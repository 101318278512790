
import GallerySliderMobile from '../bodyContent/projectsContent/GallerySliderMobile';

export default function Interviews() {
   
    return (
            
            <div className="interview">
                <GallerySliderMobile/>
            </div>

    );
}

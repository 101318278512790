import '../../../pages/projects/Projects.css'


// The Projects component displays project sections and handles animations based on mouse movements.
function LinkInBio() {

    // State and context for managing cursor visibility, animations, and global app state.

    return (
        <div className="body-container project-container">
            bio
        </div>
    )
}

export default LinkInBio
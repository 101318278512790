import '../../../pages/projects/Projects.css'
import GlobalContext from '../../../context/GlobalContext';
import {  useContext } from 'react'



// The Projects component displays project sections and handles animations based on mouse movements.
function AulartTools() {


    // State and context for managing cursor visibility, animations, and global app state.

    
    return (
        <div className="body-container project-container">
            tools
        </div>
    )
}

export default AulartTools